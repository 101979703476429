<template>
  <div>
    <b-sidebar
      id="sidebar-new-to-do"
      sidebar-class="sidebar-lg"
      :visible="newToDoSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="clear"
      @shown="onShow"
      @change="(val) => mutateNewToDoSidebar({ visible: val })"
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h4 v-if="!newToDoSidebar.isRemove">
          {{ newToDoSidebar.id ? "Editar tarefa" : "Nova tarefa" }}
        </h4>
        <h4 v-else>Remover tarefa</h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideSidebar"
        />
      </div>
      <template v-if="loading">
        <div class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" />
        </div>
      </template>
      <template v-else>
        <b-form class="p-2" @submit.prevent="onSubmit">
          <div v-if="!newToDoSidebar.isRemove">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Descrição" label-for="task-name">
                  <b-form-textarea
                    id="task-name"
                    v-model="taskName"
                    :class="{ 'is-invalid': v$.taskName.$error }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Data da tarefa" label-for="schedule-date">
                  <flat-pickr
                    id="schedule-date"
                    v-model="scheduleDate"
                    class="form-control"
                    :config="flatPickrConfig"
                    :class="{ 'is-invalid': v$.scheduleDate.$error }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Horário da tarefa" label-for="task-time">
                  <b-form-input type="time" id="task-time" v-model="taskTime" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12">

                <b-form-group class="mt-1">
                  <b-form-checkbox 
                    v-model="isImportant" 
                    name="is-important"
                  >
                    Marcar como importante
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col cols="12">
                <b-form-group
                  label-for="recurring-task"
                >
                <div>
                  <b-form-checkbox 
                    v-model="recurringTask" 
                    name="recurring-task"
                    switch
                  >
                    Repetir tarefa
                  </b-form-checkbox>
                </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="recurringTask">
              <b-col cols="6">
                <b-form-group
                  label="Repetir a cada"
                  label-for="recurring-task"
                >
                  <b-form-input
                    id="recurring-repeat"
                    v-model="repeatEveryInput"
                    type="number"
                    min="1"
                    placeholder="Apenas números"
                    :class="{ 'is-invalid': v$.repeatEveryInput.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.repeatEveryInput.$invalid">
                      Preencha os períodos a serem repetidos
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mt-2">
                <b-form-group label="" label-for="recurring-task">
                  <v-select
                    id="recurring-task"
                    v-model="repeatEverySelect"
                    :options="this.repeatEveryInput > 1 ? repeatEveryOptionsPlural : repeatEveryOptionsSingular"
                    :reduce="(repeated_list) => repeated_list.value"
                    :class="getSelectErrorClass(v$.repeatEverySelect.$error)"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.repeatEverySelect.required.$invalid">
                      Selecione o período a ser repetido
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" cols="12" v-if="repeatEverySelect === 'week'">
                <b-form-group
                  label="Dias a repetir:"
                  label-for="recurring-repeated"
                >
                  <div 
                    v-for="day in Object.keys(repeatedOptions)"
                    :key="day"
                    class="weekday-label-checkbox" 
                    :class="{ 'weekday-checked': repeatedOptions[day].checked }"
                    :weekdayChecked="true"
                    @click="(event) => weekdaySelected(event, repeatedOptions[day])">
                    {{ repeatedOptions[day].label }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="mt-1" cols="12">
                <span>Termina em</span>
              </b-col>
              <b-col cols="12">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-row>
                    <b-col cols="12" class="mt-1">
                      <b-form-radio
                        v-model="endsValue"
                        name="some-radios"
                        value="1"
                      >
                        Nunca
                      </b-form-radio>
                    </b-col>
                    <b-col cols="3" class="mt-2 d-flex align-items-center">
                      <b-form-radio
                        v-model="endsValue"
                        name="some-radios"
                        value="2"
                      >
                        Até:
                      </b-form-radio>
                    </b-col>

                    <b-col cols="9" class="mt-2">
                      <b-form-group label-for="ends-up-to" class="mb-0">
                        <flat-pickr
                          id="schedule-date"
                          v-model="inputEndsUpTo"
                          class="form-control"
                          :config="flatPickrConfigRecurrence"
                          :class="{ 'is-invalid': v$.inputEndsUpTo.$error }"
                          :disabled="endsValue != '2'"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col cols="3" class="mt-2 d-flex align-items-center">
                      <b-form-radio
                        v-model="endsValue"
                        name="some-radios"
                        value="3"
                      >
                        Após:
                      </b-form-radio>
                    </b-col>

                    <b-col
                      cols="9"
                      sm="6"
                      class="mt-2 d-flex align-items-center"
                    >
                      <b-form-group
                        label-for="recurring-task"
                        class="mb-0 ml-1 ml-sm-0"
                      >
                        <b-form-input
                          id="recurring-repeat"
                          v-model="inputEndsAfter"
                          type="number"
                          min="1"
                          placeholder="1"
                          :disabled="endsValue != '3'"
                          :class="{ 'is-invalid': v$.inputEndsAfter.$error }"
                        ></b-form-input>
                      </b-form-group>
                      <span class="ml-1">{{
                        inputEndsAfter > 1 ? "ocorrências" : "ocorrência"
                      }}</span>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group v-slot="{ ariaDescribedby }" v-if="isReschedule">
                  <b-col cols="12" class="p-0 mt-1">
                    <span>Alteração para:</span>
                  </b-col>
                  <b-row>
                    <b-col cols="12" class="mt-1">
                      <b-form-radio
                        v-model="editTaskChoice"
                        name="edit-radios"
                        value="1"
                        :class="{ 'is-invalid': v$.editTaskChoice.$error }"
                      >
                        Esta tarefa
                      </b-form-radio>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <b-form-radio
                        v-model="editTaskChoice"
                        name="edit-radios"
                        value="2"
                        :class="{ 'is-invalid': v$.editTaskChoice.$error }"
                      >
                        Todas as tarefas
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col cols="12">
                <h5>
                  Existe recorrência nesta tarefa, de que forma deseja
                  removê-la?
                </h5>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-row>
                    <b-col cols="12" class="mt-1">
                      <b-form-radio
                        v-model="removeTaskChoice"
                        name="remove-radios"
                        value="1"
                      >
                        Remover apenas esta tarefa
                      </b-form-radio>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <b-form-radio
                        v-model="removeTaskChoice"
                        name="remove-radios"
                        value="2"
                      >
                        Remover esta e as tarefas seguintes
                      </b-form-radio>
                    </b-col>
                    <b-col cols="12" class="mt-2">
                      <b-form-radio
                        v-model="removeTaskChoice"
                        name="remove-radios"
                        value="3"
                      >
                        Remover todas as tarefas
                      </b-form-radio>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div
            v-if="!newToDoSidebar.isRemove"
            class="d-flex mt-2 justify-content-between"
          >
            <b-button
              variant="outline-primary"
              @click="hideSidebar"
              class="to-do-buttons"
              >Voltar</b-button
            >
            <b-button
              variant="primary"
              type="submit"
              class="to-do-buttons"
              :disabled="saving"
              >{{ saving ? "Salvando..." : "Salvar" }}</b-button
            >
          </div>
          <div v-else class="d-flex mt-2 justify-content-between">
            <b-button
              variant="outline-primary"
              @click="hideSidebar"
              class="to-do-buttons"
              >Voltar</b-button
            >
            <b-button
              variant="primary"
              class="to-do-buttons"
              :disabled="saving"
              @click="removeTodo(newToDoSidebar)"
              >{{ saving ? "Removendo..." : "Remover" }}</b-button
            >
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BButton,
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BCol,
  BRow,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import * as types from "../store/types";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { stringToDateTime, dateTimeToString } from "@/helpers/converters";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { assertBooleanOptions } from "@/constants/assertions";
import { getVueSelectErrorClass } from "@/helpers/validators";

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    flatPickr,
    vSelect,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      flatPickrConfig: {
        dateFormat: "d/m/Y",
        enableTime: false,
        locale: Portuguese,
        minDate: "today",
      },
      flatPickrConfigRecurrence: {
        dateFormat: "Y-m-d",
        enableTime: false,
        locale: Portuguese,
        minDate: "today",
      },
      assertBooleanOptions,
      id: undefined,
      taskName: undefined,
      group: undefined,
      scheduleDate: undefined,
      taskTime: undefined,
      repeatTask: false,
      notifyTask: false,
      tag: undefined,
      isImportant: undefined,
      saving: false,
      recurringTask: false,
      repeatEveryInput: null,
      repeatEverySelect: null,
      repeatEveryOptionsPlural: [
        { value: "day", label: "Dias" },
        { value: "week", label: "Semanas" },
        { value: "month", label: "Meses" },
        { value: "year", label: "Anos" },
      ],
      repeatEveryOptionsSingular: [
        { value: "day", label: "Dia" },
        { value: "week", label: "Semana" },
        { value: "month", label: "Mês" },
        { value: "year", label: "Ano" },
      ],
      repeatedOptions: {
        sunday: { name: "Domingo", label: "D", checked: false },
        monday: { name: "Segunda-feira", label: "S", checked: false },
        tuesday: { name: "Terça-feira", label: "T", checked: false },
        wednesday: { name: "Quarta-feira", label: "Q", checked: false },
        thursday: { name: "Quinta-feira", label: "Q", checked: false },
        friday: { name: "Sexta-feira", label: "S", checked: false },
        saturday: { name: "Sábado", label: "S", checked: false }
      },
      endsValue: "1",
      endsIn: null,
      inputEndsUpTo: null,
      inputEndsAfter: null,
      editTaskChoice: null,
      editTaskFinished: null,
      removeTaskChoice: "1",
      removeTaskFinished: "current_task",
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      taskName: { required },
      scheduleDate: { required },
      recurringTask: { required },
      repeatEveryInput: {
        required: requiredIf((value) => {
          if (this.recurringTask === true && !this.newToDoSidebar.isRemove) {
            return true;
          }
          return false;
        }),
      },
      repeatEverySelect: {
        required: requiredIf((value) => {
          if (this.recurringTask === true && !this.newToDoSidebar.isRemove) {
            return true;
          }
          return false;
        }),
      },
      inputEndsUpTo: {
        required: requiredIf((value) => {
          if (
            this.recurringTask === true &&
            this.endsValue === "2" &&
            !this.newToDoSidebar.isRemove
          ) {
            return true;
          }
          return false;
        }),
      },
      inputEndsAfter: {
        required: requiredIf((value) => {
          if (
            this.recurringTask === true &&
            this.endsValue === "3" &&
            !this.newToDoSidebar.isRemove
          ) {
            return true;
          }
          return false;
        }),
      },
      editTaskChoice: {
        required: requiredIf((value) => {
          if (
            this.recurringTask === true &&
            this.isReschedule &&
            !this.newToDoSidebar.isRemove
          ) {
            return true;
          }
          return false;
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      newToDoSidebar: types.NEW_TO_DO_SIDEBAR,
    }),
    isReschedule: function () {
      return this.newToDoSidebar.id && this.newToDoSidebar.id > 0;
    },
  },
  watch: {
    endsValue(newValue, oldValue) {
      const map = {
        1: "never",
        2: "period",
        3: "quantity",
      };
      this.endsIn = map[newValue];

      if (newValue != "2") {
        this.setInputEndsUpToToNull();
      }
      if (newValue != "3") {
        this.setInputEndsAfterToNull();
      }
    },

    endsIn(newValue) {
      const map = {
        never: 1,
        period: 2,
        quantity: 3,
      };
      this.endsValue = map[newValue];
    },

    editTaskChoice(newValue) {
      const map = {
        1: "current_task",
        2: "all_tasks",
      };
      this.editTaskFinished = map[newValue];
    },

    editTaskFinished(newValue) {
      const map = {
        current_task: 1,
        all_tasks: 2,
      };
      this.editTaskChoice = map[newValue];
    },

    removeTaskChoice(newValue) {
      const map = {
        1: "current_task",
        2: "current_and_future_tasks",
        3: "all_tasks",
      };
      this.removeTaskFinished = map[newValue];
    },
  },

  mounted() {},

  methods: {
    ...mapActions({
      storeToDo: types.STORE_TO_DO,
      getTask: types.GET_TASK,
      deleteTask: types.DELETE_TASK,
    }),
    ...mapMutations({
      mutateNewToDoSidebar: types.MUTATE_NEW_TO_DO_SIDEBAR,
    }),
    setInputEndsUpToToNull() {
      this.inputEndsUpTo = null;
    },
    setInputEndsAfterToNull() {
      this.inputEndsAfter = null;
    },
    removeTodo(task) {
      this.saving = true;
      this.deleteTask({
        id: task.id,
        delete_type: this.removeTaskFinished
          ? this.removeTaskFinished
          : "current_task",
      })
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A tarefa foi removida com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.newToDoSidebar.saveAction();
        })
        .finally(() => {
          this.saving = false;
          this.hideSidebar();
        });
    },
    weekdaySelected(event, day)
    {
      if(!day.checked)
      {
        day.checked = true
      }
      else
      {
        day.checked = false
      }
    },
    onShow() {
      if (this.isReschedule) {
        this.loading = true;
        this.getTask(this.newToDoSidebar.id)
          .then((resp) => {
            var data = resp.data;
            if (data) {
              if (data.task_start) {
                let [date, time] = data.task_start.split(" ");
                this.scheduleDate = date;
                this.taskTime = time;
              }
              this.id = data.id;
              this.taskName = data.title;
              this.repeatTask = data.is_recurring;
              this.notifyTask = data.should_notify;
              this.isImportant = data.is_important == 1 ? true : false;
              this.recurringTask = data?.is_recurring;
              this.repeatEveryInput = data?.recurrence_quantity;
              this.repeatEverySelect = data?.recurrence_type;
              const selected_weekdays = JSON.parse(data?.recurrence_days_of_week);
              if (selected_weekdays) {
                Object.keys(selected_weekdays).forEach((weekday) =>
                {
                  this.repeatedOptions[weekday].checked = selected_weekdays[weekday]
                })
              }
              this.endsIn = data?.recurrence_end_type;
              this.inputEndsAfter = data?.recurrence_ends_after;
              this.inputEndsUpTo = data?.recurrence_ends_at;
              this.editTaskFinished = data?.update_type
                ? data?.update_type
                : "current_task";
              this.v$.$touch();
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar a tarefa para edição. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    hideSidebar() {
      this.mutateNewToDoSidebar({ isRemove: false, visible: false });
      this.clear();
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      let combinedDateTime = this.scheduleDate;
      combinedDateTime += this.taskTime ? " " + this.taskTime : "";
      const {
        taskName,
        isImportant,
      } = this;
      const weekdays = {}
      Object.entries(this.repeatedOptions).forEach((item) => {
        weekdays[item[0]] = item[1].checked
      })
      this.storeToDo({
        id: this.id,
        title: taskName,
        date_start: this.isReschedule ? combinedDateTime : stringToDateTime(combinedDateTime),
        is_important: isImportant,
        is_recurring: this.recurringTask,
        recurrence_quantity: this.repeatEveryInput,
        recurrence_type: this.repeatEverySelect,
        recurrence_days_of_week: weekdays,
        recurrence_end_type: this.endsIn,
        recurrence_ends_after: this.inputEndsAfter,
        recurrence_ends_at: this.inputEndsUpTo,
        is_full_day: this.taskTime ? false : true,
        update_type: this.editTaskFinished,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Tarefa salva com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateNewToDoSidebar({ visible: false });
          this.clear();
          this.newToDoSidebar.saveAction();
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar a tarefa. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    clear() {
      this.mutateNewToDoSidebar({ isRemove: false, visible: false });
      this.taskName = null;
      this.group = null;
      this.scheduleDate = null;
      this.taskTime = null;
      this.repeatTask = false;
      this.notifyTask = false;
      this.tag = null;
      this.isImportant = null;
      this.saving = false;
      this.id = null;
      this.recurringTask = false;
      this.repeatEveryInput = null;
      this.repeatEverySelect = null;
      this.endsIn = null;
      this.inputEndsAfter = null;
      this.inputEndsUpTo = null;
      this.removeTaskFinished = "current_task";
      Object.entries(this.repeatedOptions).forEach((item) => {
        item[1].checked = false
      })
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.sidebar-xg {
  width: 36rem;
}

.to-do-buttons {
  width: 10rem;
}
</style>
